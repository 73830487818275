import React from "react";

const TxnTypeColor = ({ type, value, isText = false }) => {
  const red = isText ? "text-red-400" : " bg-red-600/60";
  const green = isText ? "text-emerald-400" : " bg-emerald-600/60";
  const yellow = isText ? "text-yellow-400" : " bg-yellow-600/60";

  const colorMap = {
    Credited: green,
    Winnings: green,
    Deducted: red,
    Refund: red,
    EntryFee: red,
    Pass: yellow,
    "Pass Redeem Coins": isText ? "text-[#819144]" : "bg-[#819144]",
    default: isText ? "" : "bg-gray-700",
  };

  const getColor = () => colorMap[type] || colorMap.default;
  return (
    <div
      className={`inline ${
        !isText && "px-3"
      } text-center py-1 text-xs font-normal rounded-full gap-x-2 ${getColor()}`}
    >
      {isText ? value : type}
    </div>
  );
};

export const StatusColor = ({ value }) => {
  const red = "text-red-300 bg-red-600/60";
  const yellow = "text-yellow-300 bg-yellow-600/60";
  const green = "text-emerald-400 bg-emerald-600/60";

  const getColor = () => {
    switch (value) {
      case "Success":
        return green;

      case "Pending":
        return yellow;

      case "Failed":
        return red;

      case "Rejected":
        return "text-orange-400 bg-orange-600/60";

      default:
        return "-400 bg-gray-700";
    }
  };
  return (
    <div
      className={`inline px-3 py-1 text-xs font-normal rounded-full gap-x-2 ${getColor()}`}
    >
      {value}
    </div>
  );
};

export default TxnTypeColor;
