import {
  ALL_RESOURCES,
  ALL_RESOURCES_FAIL,
  ALL_RESOURCES_SUCCESS,
  GET_SUBSCRIBERS_LIST,
  GET_SUBSCRIBERS_LIST_FAIL,
  GET_SUBSCRIBERS_LIST_SUCCESS,
  GET_SUBSCRIPTION_COUNT,
  GET_SUBSCRIPTION_COUNT_FAIL,
  GET_SUBSCRIPTION_COUNT_SUCCESS,
  LOG_IN,
  LOG_IN_FAIL,
  LOG_IN_SUCCESS,
  MANAGE_UPI,
  MANAGE_UPI_FAIL,
  MANAGE_UPI_SUCCESS,
  QR_CODE,
  QR_CODE_FAIL,
  QR_CODE_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_SETTING,
  UPDATE_SETTING_FAIL,
  UPDATE_SETTING_SUCCESS,
  VIEW_SETTING_SUCCESS,
} from "../actions";

const initialState = {
  loading: false,
  setting: {},
  subsciberCounts: {},
  subscribers: {},
  activeDropDown: null,
  paths: [],
  upiList: [],
  upiResourceList: [],
  qrCode:[]
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACTIVE_DROPDDOWN":
      return { ...state, activeDropDown: action.payload };

    case "SET_PATHS_TOCHECK":
      const allPaths = state.paths.includes(action.payload)
        ? state.paths
        : [...state.paths, action.payload];
      return { ...state, paths: allPaths };

    // login
    case LOG_IN:
      return { ...state, loading: true };
    case LOG_IN_SUCCESS:
      return { ...state, loading: false };
    case LOG_IN_FAIL:
      return { ...state, loading: false };

    // view setting
    case VIEW_SETTING_SUCCESS:
      return { ...state, setting: action.payload, loading: false };

    // update profile
    case UPDATE_PROFILE:
      return { ...state, loading: true };
    case UPDATE_PROFILE_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_PROFILE_FAIL:
      return { ...state, loading: false };

    // update profile
    case UPDATE_SETTING:
      return { ...state, loading: true };
    case UPDATE_SETTING_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_SETTING_FAIL:
      return { ...state, loading: false };

    // GET_SUBSCRIPTION_COUNT
    case GET_SUBSCRIPTION_COUNT:
      return { ...state, loading: true };
    case GET_SUBSCRIPTION_COUNT_SUCCESS:
      return { ...state, loading: false, subsciberCounts: action.payload };
    case GET_SUBSCRIPTION_COUNT_FAIL:
      return { ...state, loading: false };

    //  GET_SUBSCRIBERS_LIST
    case GET_SUBSCRIBERS_LIST:
      return { ...state, loading: true };
    case GET_SUBSCRIBERS_LIST_SUCCESS:
      return { ...state, loading: false, subscribers: action.payload };
    case GET_SUBSCRIBERS_LIST_FAIL:
      return { ...state, loading: false };

    //  MANAGE_UPI
    case MANAGE_UPI:
      return { ...state, loading: true };
    case MANAGE_UPI_SUCCESS:
      return { ...state, loading: false, upiList: action.payload };
    case MANAGE_UPI_FAIL:
      return { ...state, loading: false };

    //  ALL_RESOURCES
    case ALL_RESOURCES:
      return { ...state, loading: true };
    case ALL_RESOURCES_SUCCESS:
      return { ...state, loading: false, upiResourceList: action.payload };
    case ALL_RESOURCES_FAIL:
      return { ...state, loading: false };
         //  QR CODE
    case QR_CODE:
      return { ...state, loading: true };
    case QR_CODE_SUCCESS:
      return { ...state, loading: false, qrCode: action.payload };
    case QR_CODE_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default authReducer;
