import axios from "axios";
import {
  ALL_RESOURCES,
  ALL_RESOURCES_FAIL,
  ALL_RESOURCES_SUCCESS,
  GET_SUBSCRIBERS_LIST,
  GET_SUBSCRIBERS_LIST_FAIL,
  GET_SUBSCRIBERS_LIST_SUCCESS,
  GET_SUBSCRIPTION_COUNT,
  GET_SUBSCRIPTION_COUNT_FAIL,
  GET_SUBSCRIPTION_COUNT_SUCCESS,
  LOG_IN,
  LOG_IN_FAIL,
  LOG_IN_SUCCESS,
  MANAGE_UPI,
  MANAGE_UPI_FAIL,
  MANAGE_UPI_SUCCESS,
  QR_CODE,
  QR_CODE_FAIL,
  QR_CODE_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_SETTING,
  UPDATE_SETTING_FAIL,
  UPDATE_SETTING_SUCCESS,
  VIEW_SETTING,
  VIEW_SETTING_FAIL,
  VIEW_SETTING_SUCCESS,
} from ".";
import { toast } from "react-hot-toast";
import {
  get_qr_code,
  get_subscriber_counts,
  get_subscribers,
  login_url,
  manage_upi,
  resource_list,
  update_password,
  update_profile,
  update_qr_code,
  update_setting,
  view_setting,
} from "../../utils/endpoints";
import { API_REQUEST, errorHandler } from "../../utils/interceptor";

// login
export const authLogin = (payload, callBack) => async (dispatch) => {
  dispatch({ type: LOG_IN });
  try {
    const response = await axios.post(login_url, payload);
    const { token, message, user, imageUrl } = response.data;
    dispatch({ type: LOG_IN_SUCCESS });
    if (token) {
      toast.success(message);
      localStorage.clear();
      localStorage.setItem("AccessToken", token);
      localStorage.setItem("Admin", JSON.stringify({ ...user, imageUrl }));
      localStorage.setItem("activeDropDown", "dashboard");
      callBack();
      window.location.reload();
    } else {
      toast.error(message);
    }
  } catch (error) {
    errorHandler(error);
    dispatch({ type: LOG_IN_FAIL });
  }
};

// updateProfile
export const updateProfile = (payload, callBack) => async (dispatch) => {
  dispatch({ type: UPDATE_PROFILE });
  try {
    const response = await axios.post(update_profile, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { message, data } = response.data;
    const adminData = JSON.parse(localStorage.getItem("Admin"));
    localStorage.setItem(
      "Admin",
      JSON.stringify({
        ...adminData,
        profilePicture: data?.result?.profilePicture,
        name: data?.result?.name,
        mobileNumber: data?.result?.mobileNumber,
      })
    );
    dispatch({ type: UPDATE_PROFILE_SUCCESS });
    toast.success(message);
    callBack();
  } catch (error) {
    errorHandler(error);
    dispatch({ type: UPDATE_PROFILE_FAIL });
  }
};

// updatePassword
export const updatePassword = (payload, callBack) => async (dispatch) => {
  dispatch({ type: UPDATE_PROFILE });
  try {
    const response = await axios.post(update_password, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { message } = response.data;
    dispatch({ type: UPDATE_PROFILE_SUCCESS });
    toast.success(message);
    callBack();
  } catch (error) {
    errorHandler(error);
    dispatch({ type: UPDATE_PROFILE_FAIL });
  }
};

// to active drop down
export const activeSidebarMenu = (data) => async (dispatch) => {
  localStorage.setItem("activeDropDown", data);
  dispatch({ type: "SET_ACTIVE_DROPDDOWN", payload: data });
};

// ------------------ Setting ---------------------- //

export const getSetting =
  (callBack = () => {}) =>
  async (dispatch) => {
    dispatch({ type: VIEW_SETTING });
    try {
      const response = await axios.get(`${view_setting}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      });
      const { data } = response.data;
      dispatch({ type: VIEW_SETTING_SUCCESS, payload: data });
      callBack(data.result);
    } catch (error) {
      errorHandler(error);
      dispatch({ type: VIEW_SETTING_FAIL });
    }
  };

export const updateSetting = (payload, callBack) => async (dispatch) => {
  dispatch({ type: UPDATE_SETTING });
  try {
    const response = await axios.post(`${update_setting}`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { message } = response.data;
    dispatch({ type: UPDATE_SETTING_SUCCESS });
    dispatch(getSetting());
    toast.success(message);

    callBack();
  } catch (error) {
    errorHandler(error);
    dispatch({ type: UPDATE_SETTING_FAIL });
  }
};

export const getSubscriptionCount = () => async (dispatch) => {
  dispatch({ type: GET_SUBSCRIPTION_COUNT });
  try {
    const response = await axios.get(`${get_subscriber_counts}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    dispatch({ type: GET_SUBSCRIPTION_COUNT_SUCCESS, payload: response.data });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: GET_SUBSCRIPTION_COUNT_FAIL });
  }
};

export const getSubscribers = (params) => async (dispatch) => {
  dispatch({ type: GET_SUBSCRIBERS_LIST });
  try {
    const response = await axios.get(`${get_subscribers}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
      params,
    });
    dispatch({ type: GET_SUBSCRIBERS_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: GET_SUBSCRIBERS_LIST_FAIL });
  }
};

// get paths to check privacy
export const setPathsToCheck = (paths) => (dispatch) => {
  dispatch({ type: "SET_PATHS_TOCHECK", payload: paths });
};

// manage upis
export const manageUPI = (props) => async (dispatch) => {
  const { params, method, payload } = props;
  dispatch({ type: MANAGE_UPI });
  try {
    const response = await API_REQUEST({
      url: manage_upi,
      method,
      data: payload,
      params,
    });
    dispatch({
      type: MANAGE_UPI_SUCCESS,
      payload: response?.data?.paymentGateway,
    });
  } catch (error) {
    dispatch({ type: MANAGE_UPI_FAIL });
  }
};

// manage upis
export const resourceData =
  ({ params }) =>
  async (dispatch) => {
    dispatch({ type: ALL_RESOURCES });
    try {
      const response = await API_REQUEST({
        url: resource_list,
        method: "GET",
        params,
      });
      dispatch({
        type: ALL_RESOURCES_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      dispatch({ type: ALL_RESOURCES_FAIL });
    }
  };


  // get qr code
  export const getQrCode = () => async (dispatch) => {
    dispatch({ type: QR_CODE });
    try {
      const response = await axios.get(`${get_qr_code}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
        // params,
      });
      dispatch({ type: QR_CODE_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandler(error);
      dispatch({ type: QR_CODE_FAIL });
    }
  };

  //  update Qr COde Setting
  export const updateQrCode = (formData , callBack) => async (dispatch) => {
    dispatch({ type: QR_CODE });
    try {
      const response = await axios.post(`${update_qr_code}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      });
      const { message } = response.data;
      dispatch({ type: QR_CODE_SUCCESS });
      callBack(response?.data)
      dispatch(getQrCode({ callBack: () => {} }));
      toast.success(message);
    } catch (error) {
      errorHandler(error);
      dispatch({ type: QR_CODE_FAIL });
    }
  };