import axios from "axios";
import {
  APPROVE_REQUEST,
  APPROVE_REQUEST_FAIL,
  APPROVE_REQUEST_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  PLAYER_LIST,
  PLAYER_LIST_FAIL,
  PLAYER_LIST_SUCCESS,
  PLAYER_VIEW,
  PLAYER_VIEW_FAIL,
  PLAYER_VIEW_SUCCESS,
  TEAM_LIST,
  TEAM_LIST_FAIL,
  TEAM_LIST_SUCCESS,
  TEAM_VIEW,
  TEAM_VIEW_FAIL,
  TEAM_VIEW_SUCCESS,
  TRANSACTION_LOGS,
  TRANSACTION_LOGS_FAIL,
  TRANSACTION_LOGS_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_FAIL,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_WALLET,
  UPDATE_USER_WALLET_FAIL,
  UPDATE_USER_WALLET_SUCCESS,
  USER_LIST,
  USER_LIST_FAIL,
  USER_LIST_SUCCESS,
  WITHDRAW_REQUEST,
  WITHDRAW_REQUEST_FAIL,
  WITHDRAW_REQUEST_SUCCESS,
  TDM_PLAYER_LISTS,
  TDM_PLAYER_LISTS_FAIL,
  TDM_PLAYER_DETAILS,
  TDM_PLAYER_DETAILS_SUCCESS,
  TDM_PLAYER_DETAILS_FAIL,
  RECORD_COUNT_SUCCESS,
  RECORD_COUNT_FAIL,
  RECORD_COUNT,
  TOTAL_AMOUNT,
  TOTAL_AMOUNT_SUCCESS,
  TOTAL_AMOUNT_FAIL,
  TRANSACTION_LIST,
  TRANSACTION_LIST_SUCCESS,
  TRANSACTION_LIST_FAIL,
  TRANSACTION_LIST_BY_Id,
  TRANSACTION_LIST_SUCCESS_BY_Id,
  TRANSACTION_LIST_FAIL_BY_Id,
  ADD_SUPPORT,
  ADD_SUPPORT_SUCCESS,
  ADD_SUPPORT_FAIL,
  View_SUPPORT,
  View_SUPPORT_SUCCESS,
  View_SUPPORT_FAIL,
  REGISTRATION_RECORD_COUNT,
  REGISTRATION_RECORD_COUNT_SUCCESS,
  REGISTRATION_RECORD_COUNT_FAIL,
  DEVICE_RECORD_COUNT,
  DEVICE_RECORD_COUNT_SUCCESS,
  DEVICE_RECORD_COUNT_FAIL,
  USER_TEAM_LIST,
  USER_TEAM_LIST_SUCCESS,
  USER_TEAM_LIST_FAIL,
  USER_VIEW,
  USER_VIEW_SUCCESS,
  USER_VIEW_FAIL,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAIL,
  MANAGE_USER_SUBSCRIIPTION,
  MANAGE_USER_SUBSCRIIPTION_SUCCESS,
  MANAGE_USER_SUBSCRIIPTION_FAIL,
  USER_PASS_LIST,
  USER_PASS_LIST_SUCCESS,
  USER_PASS_LIST_FAIL,
  SEND_MESSAGE_FAIL,
  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  FETCH_USER_MESSAGE_FAIL,
  FETCH_USER_MESSAGE_SUCCESS,
  FETCH_USER_MESSAGE,
  DELETE_USER_MESSAGE_FAIL,
  DELETE_USER_MESSAGE_SUCCESS,
  DELETE_USER_MESSAGE,
  GET_BENEFICIARY_FAIL,
  GET_BENEFICIARY_SUCCESS,
  GET_BENEFICIARY,
  PAYOUT_SUCCESS,
  PAYOUT_FAIL,
  PAYOUT,
  SPLASH_IMAGE,
  SPLASH_IMAGE_SUCCESS,
  SPLASH_IMAGE_FAIL,
  REFERRAL_IMAGE,
  REFERRAL_IMAGE_SUCCESS,
  REFERRAL_IMAGE_FAIL,
} from ".";
import {
  add_support,
  view_support,
  approve_requests,
  delete_user,
  game_played_by_user,
  player_list,
  player_view,
  record_count,
  tdm_player_view,
  team_list,
  team_view,
  total_amount,
  transaction_list,
  transaction_list_by_id,
  transaction_logs,
  update_user,
  update_user_wallet,
  user_list,
  user_status,
  withdraw_requests,
  registration_count,
  device_count,
  user_team_list,
  user_view,
  verify_user_email,
  verify_user_phone,
  user_cool_down,
  unsubscribe_user_plan,
  user_pass_list,
  manage_user_pass,
  send_user_message,
  fetch_user_message,
  delete_user_message,
  export_user_transaction,
  get_beneficiary,
  initiate_payout,
  get_splash_image,
  update_splash_image,
  get_referral_image,
  update_referral_image,
} from "../../utils/endpoints";
import { toast } from "react-hot-toast";
import { API_REQUEST, errorHandler } from "../../utils/interceptor";

// User lists
export const getUsers =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: USER_LIST });
    try {
      const response = await axios.get(
        user_list,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
          params,
        }
      );
      const { data } = response.data;
      dispatch({ type: USER_LIST_SUCCESS, payload: data });
    } catch (error) {
      errorHandler(error);
      dispatch({ type: USER_LIST_FAIL });
    }
  };

// User data
export const getUsersProfile = (userId) => async (dispatch) => {
  dispatch({ type: USER_VIEW });
  try {
    const response = await axios.get(`${user_view}/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { data } = response.data;
    dispatch({ type: USER_VIEW_SUCCESS, payload: data });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: USER_VIEW_FAIL });
  }
};

// verify user phone & email
export const verifyUser =
  (userId, payload, target, callBack) => async (dispatch) => {
    dispatch({ type: VERIFY_USER });
    try {
      const response = await axios.post(
        `${
          target == "phone" ? verify_user_phone : verify_user_email
        }/${userId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const { message } = response.data;
      toast.success(message);
      dispatch(getUsersProfile(userId));
      dispatch({ type: VERIFY_USER_SUCCESS });
      callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: VERIFY_USER_FAIL });
    }
  };

// manage user's pass
export const mangeUserPass =
  (userId, payload, callBack) => async (dispatch) => {
    dispatch({ type: MANAGE_USER_SUBSCRIIPTION });
    try {
      const response = await axios.post(
        `${manage_user_pass}/${userId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const { message } = response.data;
      toast.success(message);
      dispatch(getUsersProfile(userId));
      dispatch(userPassList(userId));

      callBack();
      dispatch({ type: MANAGE_USER_SUBSCRIIPTION_SUCCESS });
    } catch (error) {
      errorHandler(error);
      dispatch({ type: MANAGE_USER_SUBSCRIIPTION_FAIL });
    }
  };

// un subscribe user plan
export const unSubsciptionUserPlan = (userId, callBack) => async (dispatch) => {
  dispatch({ type: MANAGE_USER_SUBSCRIIPTION });
  try {
    const response = await axios.get(`${unsubscribe_user_plan}/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { message } = response.data;
    toast.success(message);
    dispatch(getUsersProfile(userId));
    callBack();
    dispatch({ type: MANAGE_USER_SUBSCRIIPTION_SUCCESS });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: MANAGE_USER_SUBSCRIIPTION_FAIL });
  }
};

// Update User Wallet
export const updateUserWallet =
  (user_id, payload, callBack) => async (dispatch) => {
    dispatch({ type: UPDATE_USER_WALLET });

    try {
      const response = await axios.post(
        `${update_user_wallet}/${user_id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const { message } = response.data;
      dispatch({ type: UPDATE_USER_WALLET_SUCCESS });
      toast.success(message);
      dispatch(getUsers());

      callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: UPDATE_USER_WALLET_FAIL });
    }
  };

// Delete User
export const deleteUser = (user_id, callBack) => async (dispatch) => {
  dispatch({ type: DELETE_USER });
  try {
    const response = await axios.get(`${delete_user}/${user_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { message } = response.data;
    dispatch({ type: DELETE_USER_SUCCESS });
    dispatch(getUsers());
    toast.success(message);

    callBack();
  } catch (error) {
    errorHandler(error);
    dispatch({ type: DELETE_USER_FAIL });
  }
};
export const ExportUserCSV = (url) => async () => {
  // dispatch({ type: DELETE_USER });
  try {
    const response = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { message } = response.data;
    // dispatch({ type: DELETE_USER_SUCCESS });
    toast.success(message);
    const a = document.createElement("a");
    a.href = response.data.file;
    a.download = "Data.csv";
    a.click();
    // callBack(response.data.file);
  } catch (error) {
    errorHandler(error);
    // dispatch({ type: DELETE_USER_FAIL });
  }
};

// Update User Status
export const updateUserStatus =
  (user_id, isCoolDdown, payload, callBack) => async (dispatch) => {
    dispatch({ type: UPDATE_USER_STATUS });
    try {
      const response = await axios.post(
        `${isCoolDdown ? user_cool_down : user_status}/${user_id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const { message } = response.data;
      dispatch(getUsers());
      dispatch({ type: UPDATE_USER_STATUS_SUCCESS });
      callBack();
      toast.success(
        isCoolDdown
          ? message
          : payload.status
          ? "User Unblock Success"
          : "User Block Success"
      );
    } catch (error) {
      errorHandler(error);
      dispatch({ type: UPDATE_USER_STATUS_FAIL });
    }
  };

// Update User
export const updateUser = (user_id, payload, callBack) => async (dispatch) => {
  dispatch({ type: UPDATE_USER });
  try {
    const response = await axios.post(`${update_user}/${user_id}`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { message } = response.data;
    dispatch({ type: UPDATE_USER_SUCCESS });
    dispatch(getUsers());
    toast.success(message);

    callBack();
  } catch (error) {
    errorHandler(error);
    dispatch({ type: UPDATE_USER_FAIL });
  }
};

// --------------------------------- Teams ---------------------------- //

// Team Lists
export const teamList = (query) => async (dispatch) => {
  dispatch({ type: TEAM_LIST });
  try {
    const response = await axios.get(`${team_list}/?${query}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { data } = response.data;
    dispatch({ type: TEAM_LIST_SUCCESS, payload: data || { results: [] } });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: TEAM_LIST_FAIL, payload: {} });
  }
};

// user team list
export const userTeamList = (query, params) => async (dispatch) => {
  dispatch({ type: USER_TEAM_LIST });
  try {
    const response = await axios.get(`${user_team_list}/?${query}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
      params,
    });
    dispatch({
      type: USER_TEAM_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: USER_TEAM_LIST_FAIL, payload: {} });
  }
};

// user pass list
export const userPassList =
  (
    userId,
    params = {
      search: "",
      page: 1,
      pageSize: 10,
      sort: "createdAt",
      order: "desc",
    }
  ) =>
  async (dispatch) => {
    dispatch({ type: USER_PASS_LIST });
    try {
      const response = await axios.get(`${user_pass_list}/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
        params,
      });
      dispatch({
        type: USER_PASS_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandler(error);
      dispatch({ type: USER_PASS_LIST_FAIL, payload: {} });
    }
  };

// tdm player Lists
export const tdmPlayersLists = (query) => async (dispatch) => {
  dispatch({ type: TDM_PLAYER_LISTS });
  try {
    const response = await axios.get(`${game_played_by_user}/${query}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { data } = response.data;
    dispatch({ type: TDM_PLAYER_LISTS, payload: data });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: TDM_PLAYER_LISTS_FAIL });
  }
};

// tdm player Details
export const tdmPlayersDetails = (query) => async (dispatch) => {
  dispatch({ type: TDM_PLAYER_DETAILS });
  try {
    const response = await axios.get(`${tdm_player_view}/${query}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { data } = response.data;
    dispatch({ type: TDM_PLAYER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: TDM_PLAYER_DETAILS_FAIL });
  }
};

// Team View
export const teamView = (team_id) => async (dispatch) => {
  dispatch({ type: TEAM_VIEW });
  try {
    const response = await axios.get(`${team_view}/${team_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { data } = response.data;
    dispatch({ type: TEAM_VIEW_SUCCESS, payload: data });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: TEAM_VIEW_FAIL });
  }
};

// --------------------------------- PLayers ---------------------------- //

// Player List
export const playerList = (team_id) => async (dispatch) => {
  dispatch({ type: PLAYER_LIST });
  try {
    const response = await axios.get(`${player_list}/${team_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { data } = response.data;
    dispatch({ type: PLAYER_LIST_SUCCESS, payload: data });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: PLAYER_LIST_FAIL });
  }
};

// Player View
export const playerView = (player_id) => async (dispatch) => {
  dispatch({ type: PLAYER_VIEW });
  try {
    const response = await axios.get(`${player_view}/${player_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { data } = response.data;
    dispatch({ type: PLAYER_VIEW_SUCCESS, payload: data });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: PLAYER_VIEW_FAIL });
  }
};

// --------------------------------- Transactions ---------------------------- //

// Transactions Logs
export const transactionLogs =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: TRANSACTION_LOGS });
    try {
      const response = await axios.get(transaction_logs, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
        params,
      });
      dispatch({ type: TRANSACTION_LOGS_SUCCESS, payload: response?.data });
    } catch (error) {
      errorHandler(error);
      dispatch({ type: TRANSACTION_LOGS_FAIL });
    }
  };

// With Draw Logs
export const withdrawRequest = (params, callBack) => async (dispatch) => {
  // const url = query ? `${withdraw_requests}${query}` : withdraw_requests;
  dispatch({ type: WITHDRAW_REQUEST });
  try {
    const response = await axios.get(withdraw_requests, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
      params,
    });
    dispatch({ type: WITHDRAW_REQUEST_SUCCESS, payload: response?.data });
    callBack && callBack();
  } catch (error) {
    errorHandler(error);
    dispatch({ type: WITHDRAW_REQUEST_FAIL });
  }
};

// get beneficiary
export const getBeneficiary = (userId, callBack) => async (dispatch) => {
  dispatch({ type: GET_BENEFICIARY });
  try {
    const { data, status, message } = await API_REQUEST({
      url: get_beneficiary + "/" + userId,
      method: "GET",
    });
    if (status) {
      callBack();
      dispatch({ type: GET_BENEFICIARY_SUCCESS, payload: data });
    } else {
      toast.error(message);
      // callBack();
    }
  } catch (error) {
    errorHandler(error);
    dispatch({ type: GET_BENEFICIARY_FAIL });
  }
};

// get beneficiary
export const initiatePayout = (payload, callBack) => async (dispatch) => {
  dispatch({ type: PAYOUT });
  try {
    const { status, message } = await API_REQUEST({
      url: initiate_payout,
      method: "POST",
      data: payload,
    });

    if (status) {
      callBack();
      dispatch({ type: PAYOUT_SUCCESS });
    } else {
      toast.error(message);
    }
  } catch (error) {
    errorHandler(error);
    dispatch({ type: PAYOUT_FAIL });
  }
};

// Approve Request
export const appvoveRequest = (payload, params) => async (dispatch) => {
  dispatch({ type: APPROVE_REQUEST });
  try {
    const response = await axios.post(approve_requests, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    dispatch({ type: APPROVE_REQUEST_SUCCESS });
    dispatch(withdrawRequest(params));
    toast.success(response.data.message);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: APPROVE_REQUEST_FAIL });
  }
};

// get record count
export const getRecordCount = () => async (dispatch) => {
  dispatch({ type: RECORD_COUNT });
  try {
    const response = await axios.get(`${record_count}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { data } = response.data;
    dispatch({ type: RECORD_COUNT_SUCCESS, payload: data });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: RECORD_COUNT_FAIL });
  }
};
export const getRegistrationCount =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: REGISTRATION_RECORD_COUNT });
    try {
      const response = await axios.get(`${registration_count}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
        params,
      });
      const { data } = response.data;
      dispatch({ type: REGISTRATION_RECORD_COUNT_SUCCESS, payload: data });
    } catch (error) {
      errorHandler(error);
      dispatch({ type: REGISTRATION_RECORD_COUNT_FAIL });
    }
  };

export const getDeviceCount = () => async (dispatch) => {
  dispatch({ type: DEVICE_RECORD_COUNT });
  try {
    const response = await axios.get(`${device_count}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { data } = response.data;
    dispatch({ type: DEVICE_RECORD_COUNT_SUCCESS, payload: data });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: DEVICE_RECORD_COUNT_FAIL });
  }
};

// get record count
export const getTotalAmount = (entry_fee) => async (dispatch) => {
  dispatch({ type: TOTAL_AMOUNT });
  try {
    const response = await axios.post(`${total_amount}`, entry_fee, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
      data: {
        type: entry_fee,
      },
    });
    const { data } = response.data;
    dispatch({ type: TOTAL_AMOUNT_SUCCESS, payload: data });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: TOTAL_AMOUNT_FAIL });
  }
};

// get transaction list
export const getTransactionList = (params) => async (dispatch) => {
  dispatch({ type: TRANSACTION_LIST });
  try {
    const response = await axios.get(`${transaction_list}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
      params,
    });
    const { data } = response?.data;
    dispatch({ type: TRANSACTION_LIST_SUCCESS, payload: data });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: TRANSACTION_LIST_FAIL });
  }
};

export const getTransactionListByUserId =
  (user_id, params = {}) =>
  async (dispatch) => {
    dispatch({ type: TRANSACTION_LIST_BY_Id });
    try {
      const response = await axios.get(`${transaction_list_by_id}/${user_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
        params,
      });
      dispatch({
        type: TRANSACTION_LIST_SUCCESS_BY_Id,
        payload: response?.data || [],
      });
    } catch (error) {
      errorHandler(error);
      dispatch({ type: TRANSACTION_LIST_FAIL_BY_Id });
    }
  };

// support
export const getSupportView = (callBack) => async (dispatch) => {
  dispatch({ type: View_SUPPORT });
  try {
    const response = await axios.get(`${view_support}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });

    callBack(response?.data?.data);
    dispatch({ type: View_SUPPORT_SUCCESS, payload: response?.data?.data });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: View_SUPPORT_FAIL });
  }
};

export const addSupport = (support_data, callBack) => async (dispatch) => {
  dispatch({ type: ADD_SUPPORT });
  try {
    const response = await axios.post(`${add_support}`, support_data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { data } = response.data;
    dispatch({ type: ADD_SUPPORT_SUCCESS, payload: data });
    dispatch(getSupportView());
    callBack();
  } catch (error) {
    errorHandler(error);
    dispatch({ type: ADD_SUPPORT_FAIL });
  }
};

// ------------------------ Send Message ---------------------- //
export const fetchMessage = (params) => async (dispatch) => {
  dispatch({ type: FETCH_USER_MESSAGE });
  try {
    const response = await API_REQUEST({
      url: fetch_user_message,
      method: "GET",
      params,
    });
    dispatch({ type: FETCH_USER_MESSAGE_SUCCESS, payload: response });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: FETCH_USER_MESSAGE_FAIL });
  }
};

export const exportUserTxn = (userId) => async () => {
  try {
    const response = await API_REQUEST({
      url: export_user_transaction + "/" + userId,
      method: "GET",
    });
    const { message } = response;
    // toast.success(message);
    const a = document.createElement("a");
    a.href = response.file;
    a.download = "user_txn.csv";
    a.click();
  } catch (error) {
    errorHandler(error);
  }
};

export const deleteMessage =
  (messageId, messageParams, callBack) => async (dispatch) => {
    dispatch({ type: DELETE_USER_MESSAGE });
    try {
      const response = await API_REQUEST({
        url: delete_user_message + "/" + messageId,
        method: "GET",
      });
      dispatch(fetchMessage(messageParams));
      callBack();
      dispatch({ type: DELETE_USER_MESSAGE_SUCCESS });
    } catch (error) {
      errorHandler(error);
      dispatch({ type: DELETE_USER_MESSAGE_FAIL });
    }
  };

export const sendMessage = (payload, callBack) => async (dispatch) => {
  dispatch({ type: SEND_MESSAGE });
  try {
    await API_REQUEST({
      url: send_user_message,
      method: "POST",
      data: payload,
    });
    callBack();
    dispatch({ type: SEND_MESSAGE_SUCCESS });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: SEND_MESSAGE_FAIL });
  }
};

// Splash Image
export const getSplashImage =
  ({ callBack }) =>
  async (dispatch) => {
    dispatch({ type: SPLASH_IMAGE });
    try {
      const response = await axios.get(`${get_splash_image}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      });

      callBack(response?.data?.data);
      dispatch({ type: SPLASH_IMAGE_SUCCESS, payload: response?.data?.data });
    } catch (error) {
      errorHandler(error);
      dispatch({ type: SPLASH_IMAGE_FAIL });
    }
  };

export const updateSplashImage = (formData , callBack) => async (dispatch) => {
  dispatch({ type: SPLASH_IMAGE });
  try {
    const response = await axios.post(`${update_splash_image}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { message } = response.data;
    dispatch({ type: SPLASH_IMAGE_SUCCESS });
    callBack(response?.data)
    dispatch(getSplashImage({ callBack: () => {} }));
    toast.success(message);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: SPLASH_IMAGE_FAIL });
  }
};


// referral image

export const getReferalImage = ({ callBack }) =>
  async (dispatch) => {
    dispatch({ type: REFERRAL_IMAGE });
    try {
      const response = await axios.get(`${get_referral_image}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      });
      callBack(response?.data?.data);
      dispatch({ type: REFERRAL_IMAGE_SUCCESS, payload: response?.data?.data });
    } catch (error) {
      errorHandler(error);
      dispatch({ type: REFERRAL_IMAGE_FAIL });
    }
  };

export const updateReferalImage = (formData , callBack) => async (dispatch) => {
  dispatch({ type: REFERRAL_IMAGE });
  try {
    const response = await axios.post(`${update_referral_image}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { message } = response.data;
    dispatch({ type: REFERRAL_IMAGE_SUCCESS });
    callBack(response?.data)
    dispatch(getReferalImage({ callBack: () => {} }));
    toast.success(message);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: REFERRAL_IMAGE_FAIL });
  }
};
