import React from "react";
import FetchLoader from "../../../components/FetchLoader";
import ButtonLoader from "../../../components/ButtonLoader";
const TDMConfirmModal = ({ show, onConfirm, loading, onCancel, message }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-secondary p-6 rounded shadow-lg max-w-md w-full">
        <h2 className="text-xl font-semibold mb-4">Confirmation</h2>
        <p className="mb-6">{message}</p>
        <div className="flex justify-end space-x-4">
          <button
            disabled={loading == true}
            className="px-4 py-2 border-green "
            onClick={onCancel}
          >
            Cancel
          </button>

          <button
            className="px-4 py-2 bg-button text-white rounded hover:border-green hover:bg-none"
            disabled={loading == true}
            onClick={onConfirm}
          >
            {loading ? <ButtonLoader /> : 'Confirm'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TDMConfirmModal;
