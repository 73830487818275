import React, { useEffect, useState } from "react";
import Logo from "../assets/Logo.png";
import {
  MdClose,
  MdOutlineGames,
  MdOutlineSpaceDashboard,
  MdDashboardCustomize,
  MdCardMembership,
  MdOutlineFeaturedPlayList,
} from "react-icons/md";
import { TbGoGame } from "react-icons/tb";
import { RiTeamLine } from "react-icons/ri";
import { AiFillSetting } from "react-icons/ai";
import { SiGithubsponsors } from "react-icons/si";
import { LiaUsersCogSolid } from "react-icons/lia";
import { AiOutlineTransaction } from "react-icons/ai";
import { HiOutlineServerStack } from "react-icons/hi2";
import { BiSolidGame, BiSupport } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { BsChevronDown, BsChevronUp, BsPass } from "react-icons/bs";
import { PiGameController, PiGitPullRequestLight } from "react-icons/pi";
import {
  FaFileImage,
  FaImages,
  FaRankingStar,
  FaRegImages,
  FaUsers,
  FaUsersRays,
} from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { activeSidebarMenu } from "../redux/actions/authAction";
import FindPermission, { staffRole } from "./FindPermission";
import { GiPodiumWinner } from "react-icons/gi";

const Sidebar = ({ handleToggle }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const activeMenu = useSelector((state) => state.authReducer.activeDropDown);
  const [activeDropDown, setActiveDropDown] = useState(activeMenu);

  // handleNavigate
  const handleNavigate = (path) => location.pathname != path && navigate(path);
  const handleDropDown = (heading) => {
    const value = activeDropDown == heading ? null : heading;
    setActiveDropDown(value);
    dispatch(activeSidebarMenu(value));
  };

  // all nav menus
  const navMenus = [
    {
      heading: "dashboard",
      name: "Dashboard",
      path: "/dashboard",
      icon: <MdOutlineSpaceDashboard />,
    },
    {
      heading: "users",
      name: "User",
      path: "/users",
      icon: <FaUsers />,
    },
    {
      heading: "other",
      name: "Saved Teams",
      path: "/saved-teams",
      icon: <RiTeamLine />,
    },
    {
      heading: "other",
      name: "Leaderboard Result",
      path: "/leaderboard",
      icon: <FaRankingStar />,
    },
    {
      heading: "notifications",
      name: "Notifications",
      path: "/notifications",
      icon: <MdOutlineNotificationsActive />,
    },
    {
      heading: "banners",
      menus: [
        { name: "Banners", path: "/banners", icon: <FaImages /> },
        {
          name: "Leader Board Banners",
          path: "/leader-board/banners",
          icon: <FaRegImages />,
        },
      ],
    },
    {
      heading: "games",
      menus: [
        {
          name: "Games",
          path: "/games",
          icon: <PiGameController />,
        },
        {
          name: "All Games",
          path: "/all/games",
          icon: <BiSolidGame />,
        },
        {
          name: "Game Types",
          path: "/games/types",
          icon: <TbGoGame />,
        },
      ],
    },
    {
      heading: "TDM",
      menus: [
    
        {
          name: "Bet Amounts",
          path: "/tdm/bet-amounts",
          icon: <MdOutlineGames />,
        },
        {
          name: "Dispute List",
          path: "/tdm/disputes",
          icon: <MdOutlineGames />,
        },
        {
          name: "Logs",
          path: "/tdm/logs",
          icon: <MdOutlineGames />,
        },
        {
          name: "Leaderboard",
          path: "/tdm/leaderboard",
          icon: <GiPodiumWinner />,
        },
        
        {
          name: "Transactions",
          path: "/tdm/transactions",
          icon: <AiOutlineTransaction />,
        },
        {
          name: "Tdm Players List",
          path: "/tdm/players-list",
          icon: <FaUsersRays />,
        },
      ],
    },
    {
      heading: "tournament",
      menus: [
        { name: "Tournaments", path: "/tournaments", icon: <MdOutlineGames /> },
        {
          name: "Daily Match Team List",
          path: "/daily-matches",
          icon: <MdOutlineGames />,
        },
      ],
    },
    // {
    //   heading: "matchlist",
    //   name: "Daily Match Team List",
    //   path: "/daily-matches",
    //   icon: <MdOutlineGames />,
    // },
    {
      heading: "league",
      menus: [
        {
          name: "Stages",
          path: "/league/stages",
          icon: <MdDashboardCustomize />,
        },
        {
          name: "Tournaments",
          path: "/league/tournaments",
          icon: <MdOutlineGames />,
        },
      ],
    },
    {
      heading: "wallet",
      name: "Transactions",
      path: "/wallet/transactions",
      icon: <AiOutlineTransaction />,
    },
    {
      heading: "withdraw request",
      name: "Withdraw Request",
      path: "/wallet/withdraw-requests",
      icon: <PiGitPullRequestLight />,
    },
    {
      heading: "battle pass",
      menus: [
        {
          name: "Features",
          path: "/battle/features",
          icon: <MdOutlineFeaturedPlayList />,
        },
        {
          name: "Passes",
          path: "/battle/passes",
          icon: <BsPass />,
        },
      ],
    },
    {
      heading: "Reports",
      menus: [
        {
          name: "GST Report",
          path: "/gst-reports",
          icon: <FaUsers />,
        },
        {
          name: "TDS Report",
          path: "/tds-reports",
          icon: <FaUsers />,
        },
        {
          name: "Credit/Debit Report",
          path: "/credit-debit-reports",
          icon: <FaUsers />,
        },
        // {
        //   name: "Debit Report",
        //   path: "/debit-reports",
        //   icon: <FaUsers />,
        // },
      ],
    },
    {
      heading: "staff & role",
      menus: [
        {
          name: "Roles",
          path: "/manage/roles",
          icon: <FaUsers />,
        },
        {
          name: "Staff",
          path: "/manage/staff",
          icon: <LiaUsersCogSolid />,
        },
      ],
    },
    // {
    //   heading: "subscriptions",
    //   menus: [
    //     {
    //       name: "Plans",
    //       path: "/subscriptions/plans",
    //       icon: <MdCardMembership />,
    //     },
    //     {
    //       name: "Subscribers",
    //       path: "/subscriptions/subscriber",
    //       icon: <FaUsers />,
    //     },
    //   ],
    // },
    {
      name: "SMS Services",
      name: "SMS Services",
      path: "/manage-sms-services",
      icon: <HiOutlineServerStack />,
    },
    {
      name: "Manage Gateways",
      name: "Manage Gateways",
      path: "/manage-gateways",
      icon: <HiOutlineServerStack />,
    },
    {
      heading: "setting",
      menus: [
        {
          name: "Splash Image",
          path: "/setting/splash",
          icon: <FaFileImage />,
        },
        {
          name: "Referral Image",
          path: "/setting/referral",
          icon: <FaFileImage />,
        },
        {
          name: "Sponsor",
          path: "/setting/sponsor",
          icon: <SiGithubsponsors />,
        },
        { name: "Host", path: "/setting/host", icon: <HiOutlineServerStack /> },

        { name: "Support", path: "/setting/support", icon: <BiSupport /> },
        { name: "App Setting", path: "/setting", icon: <AiFillSetting /> },
      ],
    },
  ];

  useEffect(() => {
    const act = localStorage.getItem("activeDropDown");
    setActiveDropDown(act);
  }, []);

  return (
    <div
      style={{ maxHeight: "100%" }}
      className="lg:z-auto relative table-container w-full z-40"
    >
      {/* Logo & Close Button */}
      <div className="flex items-center sticky top-0 bg-secondary left-0 p-4 py-5 lg:px-5 justify-between">
        <div
          onClick={() => navigate('/dashboard')}
          style={{ cursor: "pointer" }}
        >
          <img src={Logo} className="w-28 block" />
        </div>
        <MdClose
          onClick={handleToggle}
          className="text-4xl rounded-md-full bg-gray-700 p-2  lg:hidden cursor-pointer"
        />
      </div>

      {/* New Menus */}
      <section className="h-full grid gap-0.5 pb-5 px-2">
        {navMenus.map((item) => {
          const isPermitted = FindPermission(item);
          return (
            isPermitted && (
              <div key={item.heading} className="tracking-wider">
                {item?.menus ? (
                  <div className="">
                    {/* Heading */}
                    {item.heading && (
                      <div
                        type="button"
                        onClick={() => handleDropDown(item.heading)}
                        className={`flex capitalize font-medium tracking-wider items-center px-4 py-2 justify-between cursor-pointer text-gray-400`}
                      >
                        <span>{item.heading}</span>
                        {item.heading == activeDropDown ? (
                          <BsChevronUp className="text-xs" />
                        ) : (
                          <BsChevronDown className="text-xs" />
                        )}
                      </div>
                    )}

                    {/* New Menus */}
                    {(item.heading ? item.heading == activeDropDown : true) && (
                      <div className="grid px-4">
                        {item.menus.map((menus) => {
                          return (
                            (staffRole
                              ? menus.path !== "/daily-matches"
                              : true) && (
                              <div
                                key={menus.name}
                                onClick={() => handleNavigate(menus.path)}
                                className={`${
                                  location.pathname === menus.path &&
                                  "text-color icon-bg rounded-md"
                                } hover-text-color flex items-center gap-2 px-3 py-2.5 cursor-pointer `}
                              >
                                {/* <span className="text-base">{menus.icon}</span> */}
                                <li className="font-light uppercase list-disc tracking-widest text-xs">
                                  {menus.name}
                                </li>
                              </div>
                            )
                          );
                        })}
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    onClick={() => handleNavigate(item.path)}
                    className={`${
                      location.pathname === item.path &&
                      "text-color icon-bg rounded-md"
                    } hover-text-color flex text-gray-400 items-center gap-2 px-4 py-2 cursor-pointer `}
                  >
                    <span className="capitalize font-medium tracking-wider">
                      {item.name}
                    </span>
                  </div>
                )}
              </div>
            )
          );
        })}
      </section>
    </div>
  );
};

export default Sidebar;
