import React, { useEffect, useState } from "react";
import Layout from "../../layouts";
import { useDispatch, useSelector } from "react-redux";
import {
  getReferalImage,
  updateReferalImage,
} from "../../redux/actions/userAction";
import { acceptImageFileType } from "../../utils/constants";
import { MdOutlineCloudUpload } from "react-icons/md";
import ButtonLoader from "../../components/ButtonLoader";
import Heading from "../../components/Heading";

const ReferalImage = () => {
  const dispatch = useDispatch();
  const {loading}  = useSelector((state) => state?.userReducer);
  const Data = useSelector((state) => state?.userReducer?.referralImageData);
  const [formInput, setFormInput] = useState({});
  const [preview, setPreview] = useState();


  const handleChange = (event) => {
    const { name, type, files } = event.target;
    if (type === "file") {
      const file = files[0];
      setPreview(URL.createObjectURL(file));
      setFormInput({ ...formInput, [name]: file });
      handleUpdate(file);
    }
  };

  const handleUpdate = (file) => {
    const callBack = (res) => {
      const { data } = res;
      const imageUrl = data?.imageUrl + data?.result?.referralImage;
      setFormInput({});
      setPreview(imageUrl);
    };
    const formData = new FormData();
    formData.append("referralImage", file);
    dispatch(updateReferalImage(formData, callBack));
  };

  useEffect(() => {
    const callBack = () => {};
    dispatch(getReferalImage({ callBack }));
  }, []);

  return (
    <>
      <Heading title="Referal Image" />
      {!loading ? (
        <>
          <div className="grid gap-1 mt-4">
            {Data?.result?.referralImage ? (
              <div>
                <label
                  htmlFor="images"
                  className="text-xs flex flex-col gap-1 justify-center rounded border-dashed border-[1.5px] p-6 items-center"
                >
                  {Data && (
                    <img
                      src={
                        !preview
                          ? `${Data?.imageUrl}${Data?.result?.referralImage}`
                          : preview
                      }
                      alt="preview"
                      className="max-w-[170px] rounded"
                    />
                  )}
                  <MdOutlineCloudUpload className="text-xl mb-0.5" />
                  {Data?.imageUrl && " Update Image"}
                </label>
                <input
                  autoComplete="off"
                  id="images"
                  type="file"
                  name="image"
                  accept={acceptImageFileType}
                  onChange={handleChange}
                  className="rounded py-1.5 px-2 hidden outline-none border"
                />
              </div>
            ) : (
              <div className="text-center ">
                <h1 className="text-2xl">No Referral Image Found</h1>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className=" flex flex-col gap-1 justify-center rounded border-dashed border-[1.5px] p-6 items-center my-2">
          <ButtonLoader />
        </div>
      )}
    </>
  );
};

export default Layout(ReferalImage);
