import React, { useEffect, useState } from "react";
import Layout from "../../../layouts";
import Heading from "../../../components/Heading";
import { useDispatch, useSelector } from "react-redux";
import {
  getTdmLeaderBoardResult,
  tdmSettleMent,
} from "../../../redux/actions/leaderBoardAction";
import { getGames } from "../../../redux/actions/gameAction";
import Pagination from "../../../components/Pagination";
import moment from "moment";
import { BiLastPage, BiSkipNext, BiSkipPrevious } from "react-icons/bi";
import FetchLoader from "../../../components/FetchLoader";
import NoData from "../../../components/NoData";
import toast from "react-hot-toast";
import ShowOption from "../../../components/ShowOption";
import { StatusColor } from "../../../components/TxnTypeColor";
import TDMConfirmModal from "./TDMConfirmModal";

const TDMLeaderboardResult = () => {
  const dispatch = useDispatch();
  const [params, setparams] = useState({
    search: "",
    page: 1,
    pageSize: 10,
    sort: "createdAt",
    order: "desc",
  });
  const gameList = useSelector((state) => state.gameReducer.games.results);
  const { tdmleaderboard, fetchLoading } = useSelector(
    (state) => state.leaderboardReducer
  );
  const [activeTab, setActiveTab] = useState("Current");
  const { data, pagination } = tdmleaderboard;
  const [formInput, setFormInput] = useState({ game: gameList?.[3]?._id });
  const isCurrent = activeTab == "Current";
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setloading] = useState(false);

  // Show the confirmation modal
  const showConfirmationModal = (item) => {
    setSelectedItem(item);
    setModalVisible(true);
  };

  // handle change of form inputs
  const handleChange = (name, value) => {
    if (name == "game") {
      dispatch(getTdmLeaderBoardResult(params, value));
    }
    setFormInput({ ...formInput, [name]: value });
  };

  // Pagination Logic
  const handlePrev = () =>
    params.page !== 1 &&
    setparams({
      ...params,
      page: params.page - 1,
    });

  const handleForw = () =>
    params.page <= pagination?.totalPages &&
    setparams({
      ...params,
      page: params.page + 1,
    });

  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);

  // Function to get the previous Monday
  function getPreviousMonday(value) {
    const currentDate = moment();
    const previousMonday = currentDate
      .subtract(value, "weeks")
      .startOf("isoWeek");
    return previousMonday;
  }

  // Function to handle the "Last Week" button click
  function handleLastWeekClick() {
    const val = 1;
    setCurrentWeekIndex(val);
    const previousMonday = getPreviousMonday(val);
    return previousMonday.format("YYYY-MM-DD");
  }

  // Function to handle the "Next Week" button click
  function handleNextWeekClick() {
    const val = currentWeekIndex - 1;
    setCurrentWeekIndex(val);
    const previousMonday = getPreviousMonday(val);
    currentWeekIndex == 2 && setActiveTab("Last");
    return previousMonday.format("YYYY-MM-DD");
  }

  // Function to handle the "Previous" button click
  function handlePreviousClick() {
    handleChange("isNext", true);
    const val = currentWeekIndex + 1;
    setCurrentWeekIndex(val);
    const previousMonday = getPreviousMonday(val);
    return previousMonday.format("YYYY-MM-DD");
  }

  const handleWeek = (item) => {
    switch (item) {
      case "Current":
        return null;

      case "Last":
        return handleLastWeekClick();

      case "Next":
        return currentWeekIndex >= 2 && handleNextWeekClick();

      case "Previous":
        return handlePreviousClick();

      default:
        return getPreviousMonday();
    }
  };

  //Handle Settlement
  const handleSettlement = (item) => {
    const { _id, settlementAmount } = item;
    setloading(true);
    // return
    const payload = {
      settlementAmount: settlementAmount,
    };

    const callback = () => {
      setloading(false);
      setModalVisible(false);
      setActiveTab('Current')
    };
    dispatch(tdmSettleMent(_id, payload, params, callback));
  };

  // useEffect
  useEffect(() => {
    dispatch(getGames());
    if (gameList?.length > 0) {
      setFormInput({
        ...formInput,
        game: formInput?.game || gameList?.[3]?._id,
      });
      dispatch(
        getTdmLeaderBoardResult(params, formInput?.game || gameList?.[3]?._id)
      );
    }
  }, [params]);

  return (
    <div className="tracking-wider notification-container pb-12 overflow-auto h-full">
      <Heading title="TDM Leaderboard Result" />
      <section className="w-full relative bg-secondary my-2 sm:my-3 rounded shadow">
        <div className="flex flex-wrap gap-5 px-5 py-4 border-b border-gray-700 items-center justify-between">
          <span>{formInput?.weekDate}</span>

          <div className="flex gap-3">
            {/* Weeks */}
            {(activeTab != "Current"
              ? formInput?.isNext
                ? ["Previous", "Next", "Current"]
                : ["Previous", "Last", "Current"]
              : ["Last", "Current"]
            ).map((item) => {
              return (
                <>
                  <button
                    onClick={() => {
                      if (formInput.game) {
                        const isCurrent = item == "Current";
                        setActiveTab(item);
                        const weekStartDate = handleWeek(item);
                        setFormInput({
                          ...formInput,
                          weekDate: weekStartDate,
                          isNext:
                            item == "Previous" ||
                            (item == "Next" && currentWeekIndex >= 3),
                        });
                        dispatch(
                          getTdmLeaderBoardResult(
                            isCurrent
                              ? params
                              : {
                                  weekStartDate: weekStartDate,
                                  isLastWeek: true,
                                },
                            formInput?.game
                          )
                        );
                      } else {
                        toast.error("Please select game");
                      }
                    }}
                    className={`${
                      activeTab == item ? "bg-gray-500" : "bg-button"
                    } justify-center text-xs sm:text-sm flex items-center cursor-pointer tracking-wider p-1.5 sm:px-4 rounded text-white`}
                  >
                    {item == "Previous" && (
                      <BiSkipPrevious className="text-xl" />
                    )}{" "}
                    {item == "Next" && <BiSkipNext className="text-xl" />}
                    {item == "Last" && <BiLastPage className="text-xl" />}{" "}
                    {item} Week
                  </button>
                </>
              );
            })}

            {/* Game */}
            <div>
              <select
                className="outline-none icon-bg rounded p-2 appearance-none tracking-wider text-xs sm:text-sm text-center"
                value={formInput?.game}
                name="game"
                onChange={(event) => handleChange("game", event.target.value)}
              >
                {/* <option>Select game</option> */}
                {gameList?.map((item) => {
                  return <option value={item._id}>{item.name}</option>;
                })}
              </select>
            </div>
          </div>
        </div>

        {/* Table Data */}
        <div className="p-4">
          <div className="table-container">
            <table className="w-full down  text-left whitespace-nowrap">
              <thead>
                <tr>
                  <th className="p-3 px-4 title-font tracking-wider font-medium text-sm table-head rounded-tl-lg ">
                    Name
                  </th>
                  <th className="p-3 px-4 title-font tracking-wider font-medium text-sm table-head ">
                    Matches Played
                  </th>
                  <th className="p-3 px-4 title-font tracking-wider font-medium text-sm table-head">
                    Invested
                  </th>
                  <th className="p-3 px-4 title-font tracking-wider font-medium text-sm table-head">
                    Winning
                  </th>
                  <th className="p-3 px-4 title-font tracking-wider font-medium text-sm table-head">
                    Commision
                  </th>
                  <th className="p-3 px-4 title-font tracking-wider font-medium text-sm table-head">
                    Settlement
                  </th>
                  <th className="p-3 px-4 title-font tracking-wider font-medium text-sm table-head">
                    Total Amount
                  </th>

                  <th className="p-3 px-4 title-font tracking-wider font-medium text-sm table-head rounded-tr-lg ">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="text-xs relative h-full overflow-y-auto">
                {fetchLoading ? (
                  <tr>
                    <td colSpan={9} className="py-6">
                      <FetchLoader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {data?.results?.length === 0 || data?.length === 0 ? (
                      <tr>
                        <td colSpan={9}>
                          <NoData />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {data?.results.map((item, i) => (
                          <tr
                            key={i}
                            className={`${i % 2 !== 0 && "table-head"}`}
                          >
                            <td className="px-4 py-1">
                              {item?.userId?.name || "-"}
                            </td>
                            <td className="px-4 py-1">
                              {item?.matchesPlayed || "-"}
                            </td>
                            <td className="px-4 py-2">
                              {item?.investedAmount || "-"}
                            </td>
                            <td className="px-4 py-1">
                              {item?.winningAmount || "-"}
                            </td>
                            <td className="px-4 py-1">
                              {item?.totalCommission || "-"}
                            </td>
                            <td className="px-4 py-1">
                              {item?.settlementAmount || "-"}
                            </td>
                            <td className="px-4 py-1">
                              {item?.totalAmount || "-"}
                            </td>

                            <td className="px-4 py-1">
                              {item?.settlementStatus === 0 ? (
                                <ShowOption
                                  handleSettle={() =>
                                    showConfirmationModal(item)
                                  }
                                />
                              ) : (
                                <StatusColor value={"Success"} />
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {activeTab == "Current" && (
          <Pagination
            handlePrev={handlePrev}
            from={(params.page - 1) * pagination?.pageSize}
            to={params.page * pagination?.pageSize}
            total={pagination?.totalItems}
            handleForw={handleForw}
          />
        )}

        {/* Confirmation Modal */}
        <TDMConfirmModal
          show={isModalVisible}
          onConfirm={() => handleSettlement(selectedItem)}
          onCancel={() => setModalVisible(false)}
          loading={loading}
          message="Are you sure you want to settle this user? This action is irreversible."
        />
      </section>
    </div>
  );
};

export default Layout(TDMLeaderboardResult);
