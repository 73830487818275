import axios from "axios";
import {
  GET_LEADERBOARD_RESULT_LIST,
  GET_LEADERBOARD_RESULT_LIST_FAIL,
  GET_LEADERBOARD_RESULT_LIST_SUCCESS,
  GET_TDM_LEADERBOARD_RESULT_LIST,
  GET_TDM_LEADERBOARD_RESULT_LIST_FAIL,
  GET_TDM_LEADERBOARD_RESULT_LIST_SUCCESS,
  TDM_SETTLEMENT,
  TDM_SETTLEMENT_FAIL,
  TDM_SETTLEMENT_SUCCESS,
} from ".";
import {
  get_currentweek_result,
  get_lastweek_result,
  get_lastweek_tdm_result,
  get_tdm_result,
  tdm_settlement,
} from "../../utils/endpoints";
import { errorHandler } from "../../utils/interceptor";
import toast from "react-hot-toast";

// get Leader Board Result
export const getLeaderBoardResult =
  (params = {}, isCurrentWeek) =>
  async (dispatch) => {
    const gameId = "64e486500306235372baef10";
    dispatch({ type: GET_LEADERBOARD_RESULT_LIST });
    try {
      const response = await axios.get(
        `${
          isCurrentWeek ? get_currentweek_result : get_lastweek_result
        }/${gameId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
          params,
        }
      );
      const { data } = response;
      if (data?.results?.length === 0) {
      } else {
        dispatch({ type: GET_LEADERBOARD_RESULT_LIST_SUCCESS, payload: data });
      }
    } catch (error) {
      errorHandler(error);
      dispatch({
        type: GET_LEADERBOARD_RESULT_LIST_FAIL,
        payload: {
          result: [],
          paginnation: {},
        },
      });
    }
  };

// get TDM Leader Board Result
export const getTdmLeaderBoardResult =
  (params = {}, gameId) =>
  async (dispatch) => {
    dispatch({ type: GET_TDM_LEADERBOARD_RESULT_LIST });
    try {
      const response = await axios.get(`${get_tdm_result}/${gameId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
        params,
      });
      const { data } = response;
      if (data?.results?.length === 0) {
      } else {
        dispatch({
          type: GET_TDM_LEADERBOARD_RESULT_LIST_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      errorHandler(error);
      dispatch({
        type: GET_TDM_LEADERBOARD_RESULT_LIST_FAIL,
        payload: {
          result: [],
          paginnation: {},
        },
      });
    }
  };

export const tdmSettleMent =
  (_id, payload, params, callBack) => async (dispatch) => {
    dispatch({ type: TDM_SETTLEMENT });
    try {
      const response = await axios.post(`${tdm_settlement}/${_id}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      });
      const { message } = response.data;
      dispatch({ type: TDM_SETTLEMENT_SUCCESS });
      dispatch(getTdmLeaderBoardResult(params, _id));
      toast.success(message);
      callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: TDM_SETTLEMENT_FAIL });
    }
  };
